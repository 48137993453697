export default {
    name: 'TakeNote',
    data() {
        return {
            noteText: '',
            isEdit: false,
            overlay: false,
            studentIDEdit: ''
        }
    },
    methods: {
        btn_Edit() {
            this.isEdit = true
        },
        openEditNote(item) {
            if (item.remarks == null || item.remarks == '') this.isEdit = true
            else this.isEdit = false
            this.overlay = true
            this.studentIDEdit = item.studentCode.substr(0, 5)
            this.noteText = item.remarks
        },
    },
}