<template>
  <div v-if="currentRole === 4 || currentRole === 5" class="mt-5 ml-10 mr-10" id="vis-detail">
    <h2 class="mb-3">Visitor report</h2>
    <v-col>
      <v-row class="mb-0">
        <v-select
          class="ml-1"
          :items="locationPermissions"
          item-text="location"
          item-value="locationID"
          append-icon="keyboard_arrow_down"
          outlined
          @change="locationChange"
          dense
          :class="'input-select'"
          hide-details
          placeholder="Center"
          v-model="locationID"
        >
        </v-select>
        <v-text-field
          type="date"
          v-model="fromDate"
          outlined
          dense
          @input="dateChangeFrom"
          :class="'input-select ml-1'"
          hide-details
          lable="From Date"
        />
        <v-text-field
          type="date"
          v-model="toDate"
          outlined
          @input="dateChangeTo"
          lable="To Date"
          :style="{ width: '150px' }"
          :class="'input-select ml-1'"
          dense
        />
        <div class="ml-auto">
          <v-btn class="d-flex primary ml-auto" @click="downloadExcel"
            >Export Visitor Report Table</v-btn
          >
          <div v-if="checkError" class="error">
            <v-dialog v-model="checkError" persistent max-width="290">
              <v-toolbar
                color="warning"
                light
                style="color: #fff"
                class="text-h5 d-flex align-center"
                ><v-icon class="mr-3">mdi-alert-circle</v-icon>Table has no data</v-toolbar
              >
            </v-dialog>
          </div>
        </div>
      </v-row>
      <div class="alert">
        <v-alert
          :value="isError"
          transition="scale-transition"
          type="error"
          style="font-size: 14px; padding: 12px"
        >
          User doesn't exist
        </v-alert>
      </div>
    </v-col>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <v-row no-gutters>
      <v-col md="12">
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items="filtered"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          class="elevation-0 table-visitor"
          fixed-header
          height="70vh"
        >
          <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
              <tr class="visitor-header">
                <th
                  :style="{
                    background: header.color,
                    color: '#000 !important',
                    width: header.width,
                  }"
                  v-for="header in props.headers"
                  :key="header.value"
                >
                  <span>{{ header.text }}</span>
                </th>
              </tr>
              <tr class="filter">
                <td
                  v-for="header in props.headers"
                  :key="header.value"
                  :style="{
                    position: 'sticky',
                    top: '58px',
                    background: '#ffff',
                    borderBottom: '1px solid  #d1d4d4',
                  }"
                >
                  <div :class="'d-flex justify-center'" v-if="filters[header.value]">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="''"
                      placeholder="select"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                  <div v-else>
                    <td></td>
                  </div>
                </td>
              </tr>
            </thead>
          </template>
          <template v-slot:[`item.studentName`]="{ item }">
            <p style="width: 200px" class="link" @click="viewDetailUser(item.stu_userID)">
              {{ item.studentName }}
            </p>
          </template>
          <template v-slot:[`item.studentCode`]="{ item }">
            <p style="width: 100px">{{ item.studentCode }}</p>
          </template>
          <template v-slot:[`item.lastCallMsg`]="{ item }">
            <p :style="{ width: '300px' }">{{ item.lastCallMsg }}</p>
          </template>
          <template v-slot:[`item.totPaidAmt`]="{ item }">
            <p>{{ item.totPaidAmt.toLocaleString() }}</p>
          </template>
          <template v-slot:[`item.homePhone`]="{ item }">
            <p :style="{ width: '150px' }">{{ item.homePhone }}</p>
          </template>
          <template v-slot:[`item.mobilePhone`]="{ item }">
            <p :style="{ width: '150px' }">{{ item.mobilePhone }}</p>
          </template>
          <template v-slot:[`item.englishSchool`]="{ item }">
            <p :style="{ width: '200px' }">{{ item.englishSchool }}</p>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <p :style="{ width: '200px' }">{{ item.address }}</p>
          </template>
          <template v-slot:[`item.counselorName`]="{ item }">
            <p :style="{ width: '200px' }">{{ item.counselorName }}</p>
          </template>
          <template v-slot:[`item.remarks`]="{ item }">
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.remarks.length <= 0"
                    :style="{ width: '18px' }"
                    @click="openEditNote(item)"
                    src="@/assets/images/new-note.svg"
                    alt=""
                  />
                  <img
                    v-bind="attrs"
                    v-on="on"
                    v-else
                    :style="{ width: '18px' }"
                    @click="openEditNote(item)"
                    src="@/assets/images/notes.svg"
                    alt=""
                  />
                </template>
                <span v-if="item.remarks.length <= 0">Create note</span>
                <span v-else>View note</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
        <v-data-table
          :headers="headers"
          :items="filtered"
          :items-per-page="1000000"
          hide-default-footer
          class="elevation-0"
          id="table-visitor-exported"
          fixed-header
          height="0vh"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.studentCode }}</td>
              <td>{{ item.studentName }}</td>
              <td>{{ item.dob }}</td>
              <td>{{ item.bookPT }}</td>
              <td>{{ item.isPT }}</td>
              <td>{{ item.registered }}</td>
              <td>{{ item.counselorName }}</td>
              <td>{{ item.englishSchool }}</td>
              <td>{{ item.sourceReference }}</td>
              <td>{{ item.lastCallMsg }}</td>
              <td>{{ item.crtdDate }}</td>
              <td>{{ item.ec }}</td>
              <td>{{ item.interViewer }}</td>
              <td>{{ item.totPaidAmt }}</td>
              <td>{{ item.paymentDate }}</td>
              <td>{{ item.startDate }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.homePhone }}</td>
              <td>{{ item.mobilePhone }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.remarks }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="overlay" width="600">
      <v-card light>
        <v-card-title class="text-h5" style="font-weight: 700"> Student notes </v-card-title>
        <v-divider></v-divider>
        <v-textarea
          v-model="noteText"
          name="input-student-remarks"
          label="Add lesson notes"
          :disabled="!isEdit"
          outlined
          dense
          height="30vh"
          class="mt-3 ml-5 mr-5"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="mb-4">
            <v-btn elevation="2" class="mr-3" large @click="overlay = false">Cancel</v-btn>
            <v-btn
              v-if="isEdit === true"
              elevation="2"
              large
              color="primary"
              @click="saveNote"
              :disabled="!noteText"
              >Save</v-btn
            >
            <v-btn elevation="2" large color="primary" @click="btn_Edit" v-else>Edit</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="!isLoading" color="#fff" :class="'w-100 fix pl-10 pr-10 footer'">
      <v-row>
        <v-col md="2">
          <v-select
            outlined
            hide-details
            dense
            :value="itemsPerPage"
            :items="[20, 50, 100, 200]"
            @change="itemsPerPage = parseInt($event, 10)"
            style="height: 32px"
          ></v-select>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-end"> Total : {{ filtered.length }}</v-col>
        <v-col md="6" class="d-flex justify-end">
          <v-pagination
            v-model="page"
            :length="pageCount"
            elevation="0"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx-js-style'
import ViewDetail from '@/mixins/viewDetail.js'
import TakeNote from '@/mixins/takeNote.js'
import { visitor_report } from '@/api/visitor_report.js'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import { report_revenue } from '@/api/report-revenuereport'
// import Multiselect from "vue-multiselect";
import dayjs from 'dayjs'

export default {
  components: {
    Autocomplete,
  },
  mixins: [ViewDetail, TakeNote],
  data() {
    return {
      currentRole: JSON.parse(localStorage.getItem('currentUser')).userInfo.accountTypeID,
      page: 1,
      locationID: parseInt(localStorage.getItem('currentCenter')),
      checkError: false,
      isLoading: false,
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
        .toISOString()
        .split('T')[0],
      toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        .toISOString()
        .split('T')[0],
      locationPermissions: JSON.parse(localStorage.getItem('currentUser')).locationPermissions,
      pageCount: 0,
      itemsPerPage: 100,
      headers: [
        {
          text: 'Stu. Code',
          value: 'studentCode',
          color: '#A0D589',
          sortable: false,
        },
        {
          text: 'Student name',
          value: 'studentName',
          color: '#A0D589',
          sortable: false,
        },
        {
          text: 'DOB',
          value: 'dob',
          color: '#A0D589',
          sortable: false,
        },

        {
          text: 'Book PT',
          value: 'bookPT',
          color: '#FFCF53',
          sortable: false,
        },
        {
          text: 'Done PT',
          value: 'isPT',
          color: '#FFCF53',
          sortable: false,
        },
        {
          text: 'Registered',
          value: 'registered',
          color: '#FFCF53',
          sortable: false,
        },
        {
          text: 'Counselor',
          value: 'counselorName',
          color: '#FDE9D9',
          sortable: false,
        },
        {
          text: 'School',
          value: 'englishSchool',
          color: '#BFCDFF',
          sortable: false,
        },
        {
          text: 'Source Reference',
          value: 'sourceReference',
          color: '#FFCF53',
          sortable: false,
        },
        {
          text: 'Last call msg ',
          value: 'lastCallMsg',
          color: '#BFCDFF',
          sortable: false,
        },
        {
          text: 'Create date',
          value: 'crtdDate',
          color: '#A0D589',
          sortable: false,
        },
        {
          text: 'Test date report',
          value: 'ec',
          color: '#A0D589',
          sortable: false,
        },

        {
          text: 'Interviewer',
          value: 'interViewer',
          color: '#FEBFFF',
          sortable: false,
        },
        {
          text: 'Paid amount',
          value: 'totPaidAmt',
          color: '#FFCF53',
          sortable: false,
        },
        {
          text: 'Payment Date',
          value: 'paymentDate',
          color: '#A0D589',
          sortable: false,
        },
        {
          text: 'Start date',
          value: 'startDate',
          color: '#A0D589',
          sortable: false,
        },
        {
          text: 'Email',
          value: 'email',
          color: '#BFCDFF',
          sortable: false,
        },
        {
          text: 'Home phone',
          value: 'homePhone',
          color: '#A0D589',
          sortable: false,
        },
        {
          text: 'Mobile phone',
          value: 'mobilePhone',
          color: '#BFCDFF',
          sortable: false,
        },
        {
          text: 'Address',
          value: 'address',
          color: '#BFCDFF',
          sortable: false,
        },
        {
          text: 'Note',
          width: '200px',
          value: 'remarks',
          color: '#BFCDFF',
          sortable: false,
        },
      ],
      listVisit: [],
      filters: {
        studentCode: [],
        fullName: [],
        dob: [],
        homePhone: [],
        mobilePhone: [],
        studentName: [],
        englishSchool: [],
        bookPT: [],
        isPT: [],
        registered: [],
        counselorName: [],
        totPaidAmt: [],
        sourceReference: [],
        lastCallMsg: [],
        crtdDate: [],
        ec: [],
        email: [],
        address: [],
        interViewer: [],
        paymentDate: [],
        startDate: [],
        remarks: [],
      },
    }
  },

  computed: {
    filtered() {
      const listReport = this.listVisit.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'registered'
              ? this.filters[f].reduce((value, el) => {
                  if (!d.registered && el === 'Not registered') {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f])) ||
            this.filters[f].includes(d[f])
          )
        })
      })

      return listReport
    },
  },
  created() {
    if (localStorage.getItem('VisFrom') !== null) this.fromDate = localStorage.getItem('VisFrom')
    if (localStorage.getItem('VisTo') !== null) this.toDate = localStorage.getItem('VisTo')
    if (localStorage.getItem('VisCenter') !== null)
      this.locationID = JSON.parse(localStorage.getItem('VisCenter'))
    this.getVisitorList()
    if (localStorage.getItem('VisFilter') !== null) {
      this.filters = JSON.parse(localStorage.getItem('VisFilter'))
    }
  },

  methods: {
    // changeFilter() {
    //   localStorage.setItem('VisFilter', JSON.stringify(this.filters))
    // },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('VisFilter', JSON.stringify(this.filters))
    },
    downloadExcel() {
      if (this.filtered.length > 0) {
        const locationName = this.locationPermissions.find(
          el => el.locationID === this.locationID
        ).location

        var table_elt = document.getElementById('table-visitor-exported')
        var workbook = XLSX.utils.table_to_book(table_elt)
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        const columnWidths = [
          { wch: 10 },
          { wch: 25 },
          { wch: 13 },
          { wch: 10 },
          { wch: 10 },
          { wch: 15 },
          { wch: 25 },
          { wch: 25 },
          { wch: 15 },
          { wch: 20 },
          { wch: 13 },
          { wch: 13 },
          { wch: 25 },
          { wch: 13 },
          { wch: 13 },
          { wch: 13 },
          { wch: 25 },
          { wch: 13 },
          { wch: 13 },
          { wch: 25 },
          { wch: 60 },
        ]
        worksheet['!cols'] = columnWidths

        const title =
          'Visitor Report from ' +
          dayjs(this.fromDate).format('DD-MM-YYYY') +
          ' to ' +
          dayjs(this.toDate).format('DD-MM-YYYY') +
          ' of ' +
          locationName
        XLSX.writeFile(workbook, title + '.xlsm')
      } else {
        this.checkError = true
        setTimeout(() => {
          this.checkError = false
        }, 2000)
      }
    },
    dateChangeTo() {
      localStorage.setItem('VisTo', this.toDate)
      this.filters = {
        studentCode: [],
        fullName: [],
        dob: [],
        homePhone: [],
        mobilePhone: [],
        studentName: [],
        englishSchool: [],
        bookPT: [],
        isPT: [],
        registered: [],
        counselorName: [],
        remarks: [],
        totPaidAmt: [],
        sourceReference: [],
        lastCallMsg: [],
        crtdDate: [],
        ec: [],
        email: [],
        address: [],
        interViewer: [],
        paymentDate: [],
        startDate: [],
      }
      this.getVisitorList()
    },
    locationChange() {
      localStorage.setItem('VisCenter', this.locationID)
      this.filters = {
        studentCode: [],
        fullName: [],
        dob: [],
        homePhone: [],
        mobilePhone: [],
        studentName: [],
        englishSchool: [],
        bookPT: [],
        isPT: [],
        registered: [],
        counselorName: [],
        remarks: [],
        totPaidAmt: [],
        sourceReference: [],
        lastCallMsg: [],
        crtdDate: [],
        ec: [],
        email: [],
        address: [],
        interViewer: [],
        paymentDate: [],
        startDate: [],
      }
      this.getVisitorList()
    },
    dateChangeFrom() {
      localStorage.setItem('VisFrom', this.fromDate)
      this.filters = {
        studentCode: [],
        fullName: [],
        dob: [],
        homePhone: [],
        mobilePhone: [],
        studentName: [],
        englishSchool: [],
        bookPT: [],
        isPT: [],
        registered: [],
        counselorName: [],
        remarks: [],
        totPaidAmt: [],
        sourceReference: [],
        lastCallMsg: [],
        crtdDate: [],
        ec: [],
        email: [],
        address: [],
        interViewer: [],
        paymentDate: [],
        startDate: [],
      }
      this.getVisitorList()
    },
    async getVisitorList() {
      this.isLoading = true
      this.listVisit = []
      await visitor_report.getVisitorList(this.locationID, this.fromDate, this.toDate).then(res => {
        this.listVisit = res.map(item => {
          return {
            ...item,
            dob: item.dob === null ? '' : this.formatTime(new Date(item.dob.split('T')[0])),
            paymentDate:
              item.paymentDate === null
                ? ''
                : this.formatTime(new Date(item.paymentDate.split('T')[0])),
            crtdDate:
              item.crtdDate === null ? '' : this.formatTime(new Date(item.crtdDate.split('T')[0])),
            startDate:
              item.startDate === null
                ? ''
                : this.formatTime(new Date(item.startDate.split('T')[0])),
          }
        })
        this.isLoading = false
      })
    },
    saveNote() {
      report_revenue.stoppedUpdateNote(this.studentIDEdit, encodeURIComponent(this.noteText))
      this.overlay = false
      this.getVisitorList()
    },
    formatTime(date) {
      const yyyy = date.getFullYear()
      let mm = date.getMonth() + 1 // Months start at 0!
      let dd = date.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      const formattedToday = dd + '/' + mm + '/' + yyyy
      return formattedToday
    },
    //async init() {
    // this.isLoading = true;
    // this.isChangeDateField = false;
    // this.listVisit = [];

    // if (this.listLocation.length !== 0) {
    //   let fromDate = this.year + "-" + this.month + "-01";
    //   let toDate = this.year + "-" + this.month + "-" + new Date(this.year, this.month, 0).getDate();
    //   await book_management
    //     .getInvtOrderByCenter(this.getLocationId(), fromDate, toDate)
    //     .then((res) => {
    //       if (res) {
    //         res.forEach((x) => {
    //           this.listVisit.push({
    //             productCode: x.productCode,

    //             totQtyOrd: x.totQtyOrd,
    //             actualQty: x.actualQty,
    //             qtyRcvd: x.qtyRcvd,
    //             qtyReturned: x.qtyReturned,
    //             unit: x.unit === "Bộ" ? "Bộ" : x.unit,
    //             description: x.description,

    //             quantityRemain: x.actualQty - x.qtyRcvd
    //           });
    //         });

    //         this.isChooseDate = false;
    //         this.isLoading = false;
    //       } else {
    //         this.errorDialog = true;
    //       }
    //     })
    //     .catch(() => {
    //       this.errorDialog = true;
    //     });
    // }
    //  },
    //   getLocationId() {
    //     if (this.listLocation.length === 0) {
    //       return;
    //     } else {
    //       return this.listLocation.find((element) => {
    //         return element.name === this.location;
    //       }).id;
    //     }
    //   },
    groupColumnValueList(val) {
      if (val === 'isPT' || val === 'bookPT' || val === 'totPaidAmt') {
        return [...new Set(this.listVisit.map(d => d[val]))]
      } else if (val === 'registered') {
        const registeredList = this.listVisit.map(d => d[val]).filter(y => y)
        registeredList.unshift('Not registered')
        return registeredList
      } else return this.listVisit.map(d => d[val]).filter(y => y)
    },
    toggle(value) {
      this.$nextTick(() => {
        if (
          this.filters[value].length ==
          this.listVisit
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, []).length
        ) {
          this.filters[value] = []
          localStorage.setItem(
            'VisFilter',
            JSON.stringify({
              ...this.filters,
              [value]: [],
            })
          )
          localStorage.setItem(
            'VisFilter',
            JSON.stringify({
              ...this.filters,
              [value]: [],
            })
          )
        } else {
          this.filters[value] = this.listVisit
            .map(d => d[value])
            .reduce(function (accumulator, element) {
              if (accumulator.indexOf(element) === -1) {
                accumulator.push(element)
              }
              return accumulator
            }, [])
            .slice()
          localStorage.setItem(
            'VisFilter',
            JSON.stringify({
              ...this.filters,
              [value]: this.listVisit
                .map(d => d[value])
                .reduce(function (accumulator, element) {
                  if (accumulator.indexOf(element) === -1) {
                    accumulator.push(element)
                  }
                  return accumulator
                }, [])
                .slice(),
            })
          )
        }
      })
    },
    back() {
      window.history.back()
    },

    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.link {
  cursor: pointer;

  color: #427df2 !important;
}
.link:hover {
  text-decoration: underline;
  font-weight: 700;
}
.input-select {
  max-width: 190px;
}

table {
  border-collapse: collapse;
}

.v-data-table-header tr:nth-of-type(1) th {
  background: #0b2585;
}

.v-data-table-header tr:nth-of-type(2) th {
  background: #f8fafb;
}
.filter td:nth-of-type(1) {
  z-index: 6 !important;
  left: 0;
}
.filter td:nth-of-type(2) {
  z-index: 6 !important;
  left: 130px;
}
.visitor-header th:nth-child(1) {
  z-index: 5;
  min-width: 130px;
  span {
    width: 100px;
  }
  position: sticky;
  left: 0px;
}
.visitor-header th:nth-child(2) {
  z-index: 5;
  min-width: 200px;
  span {
    width: 200px;
  }
  position: sticky;
  left: 130px;
}
.footer {
  padding: 0 !important;
  margin-top: 20px;
}
.alert {
  width: 300px;
  position: absolute;
  right: 4%;
  top: 5%;
  z-index: 999;
}
</style>
